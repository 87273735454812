import CryptoJS from 'crypto-js';

export default () => {
    if (!window.crypto || !window.crypto.subtle || typeof window.crypto.subtle.digest !== 'function') {
        if (!window.crypto) {
            window.crypto = {};
        }
        if (!window.crypto.subtle) {
            window.crypto.subtle = {};
        }
        window.crypto.subtle.digest = async function(algorithm, data) {
            return new Promise((resolve, reject) => {
                try {
                    const hash = CryptoJS.SHA256(CryptoJS.lib.WordArray.create(data));
                    resolve(new Uint8Array(hash.words.map(word => [
                        (word >> 24) & 0xFF,
                        (word >> 16) & 0xFF,
                        (word >> 8) & 0xFF,
                        word & 0xFF
                    ]).flat()));
                } catch (error) {
                    reject(error);
                }
            });
        };
        console.log('Polyfill for crypto.subtle.digest applied');
    } else {
        console.log('crypto.subtle.digest is natively supported');
    }
};
