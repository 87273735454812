<template>
    <footer class="footer footer-2">
        <div class="footer-bottom">
            <div :class="isFullwidth ? 'container-fluid' : 'container'">
                <p id="Desktopview" class="footer-copyright">{{$t("components.footer_default.Copyright")}}{{merchant_name}}{{$t("components.footer_default.description_title")}}</p>
                <p id="mobileview" class="footer-copyright">{{$t("components.footer_default.Copyright")}}{{merchant_name}}{{$t("components.footer_default.description_title_2")}}</p>
                <ul class="footer-menu" v-if="MerchantSettings.policy_url !== null && MerchantSettings.policy_url !== ''">
                    <!-- <li>
                        <a href="#">Terms Of Use</a>
                    </li> -->
                    <li>
                        <a :href="MerchantSettings.policy_url" target="_blank" class="policy-url">{{$t("components.footer_default.privacy_policy")}}</a>
                    </li>
                </ul>

                <div class="social-icons social-icons-color">
                    <a
                        :href="merchantDetails.facebook_url"
                        class="social-icon social-facebook"
                        title="Facebook"
                        target="_blank"
                        v-if="merchantDetails.facebook_url !== null && merchantDetails.facebook_url !== ''"
                    >
                        <i class="icon-facebook-f"></i>
                    </a>
                    <a :href="merchantDetails.twitter_url" class="social-icon social-twitter" title="Twitter" target="_blank" v-if="merchantDetails.twitter_url !== null && merchantDetails.twitter_url !== ''">
                        <i class="icon-twitter"></i>
                    </a>
                    <a
                        :href="merchantDetails.instagram_url"
                        class="social-icon social-instagram"
                        title="Instagram"
                        target="_blank"
                        v-if="merchantDetails.instagram_url !== null && merchantDetails.instagram_url !== ''"
                    >
                        <i class="icon-instagram"></i>
                    </a>
                    <a :href="merchantDetails.youtube_url" class="social-icon social-youtube" title="Youtube" target="_blank" v-if="merchantDetails.youtube_url !== null && merchantDetails.youtube_url !== ''">
                        <i class="icon-youtube"></i>
                    </a>
                    <!-- <a
                        href="#"
                        class="social-icon social-pinterest"
                        title="Pinterest"
                        target="_blank"
                    >
                        <i class="icon-pinterest"></i>
                    </a> -->
                </div>
            </div>
        </div>
        <div class="mb-10" v-if="bottomSticky"></div>
	</footer>
</template>

<script>
export default {
    data: function () {
        return {
            bottomSticky: false,
            merchantDetails: '',
            MerchantSettings: '',
            merchant_name: '',
            environment: ''
        }
    },
    computed: {
        isFullwidth: function() {
            return this.$route.path.includes('fullwidth');
        }
    },
    watch: {
        $route: function () {
            this.handleBottomSticky();
        }
    },
    mounted: function () {
        this.environment = this.$store.state.sessionStorage.environment
        this.handleBottomSticky();
        window.addEventListener('resize', this.handleBottomSticky, {passive: true});
        this.getMerchantDetails();
        this.merchantSettings();
    },
    destroyed: function () {
        window.removeEventListener('resize', this.handleBottomSticky);
    },
    methods: {
        faroLog(data) {
            var message = "application:" + "Mz-WALLET, environment:" + `${this.environment}` + ", level:" + `${data.level}` + ", module:" + `CartMenu Page (${data.api})` + ", msg:" + `${data.msg}` + ", time:" + `${new Date()}`
            return message
        },
        handleBottomSticky: function () {
            this.bottomSticky = this.$route.path.includes('/product/default') && (window.innerWidth > 991);
        },

                    // <================================ Merchant Detail Api function ====================================>

            getMerchantDetails: function() {
            var url = `/api/mzapps/merchant/${this.$store.state.sessionStorage.merchant_id}?country_code=${this.country}&timestamp=${new Date().getTime()}`
            this.$axios.$get(url).then(response => {
                this.merchantDetails = response.merchant;
                console.log('FoootermerchantDetails',this.merchantDetails);
                 this.merchant_name = this.merchantDetails.merchant_name.trim()
                // document.title = `Official E-Voucher Store | ${this.merchantDetails.merchant_name}`
            }).catch(e => {
                var error_params = {
                    msg: e.data ? e.data.message : e,
                    api: 'merchant Api',
                    level: 'error'
                }
                var error_text = this.faroLog(error_params)
                faro.api.pushError(new Error(error_text))
            })
        }, 
        
                    // <================================ Merchant Settings Api function ====================================>

            merchantSettings: function() {
            var url = `/api/mzapps/settings?country_code=${this.country}&timestamp=${new Date().getTime()}`
            this.$axios.$get(url).then(response => {
                console.log("merchantSettings ", response)
                this.MerchantSettings = response;
            }).catch(e => {
                var error_params = {
                    msg: e.data ? e.data.message : e,
                    api: 'settings Api',
                    level: 'error'
                }
                var error_text = this.faroLog(error_params)
                faro.api.pushError(new Error(error_text))
            })
        },
        
        //     async merchantSettings(){
        //             var url = `/api/mzapps/settings`;
        //             await this.$axios.$get(url).then(response => {
        //                     console.log("merchantSettings ", response)
        //                     this.MerchantSettings = response;
        //                     if(this.MerchantSettings !== undefined && this.MerchantSettings !== null){
        //                         this.$store.commit('sessionStorage/assignmerchantSetting', this.MerchantSettings);
        //             if(this.MerchantSettings.shop_status === 'A'){
        //                 $('body').append(this.MerchantSettings.body_script_end);
        //             $('body').prepend(this.MerchantSettings.body_script_start);
        //             $('head').append(this.MerchantSettings.header_script);
        //             console.log('sessionmerchant',this.$store.state.sessionStorage.merchantSettings);
        //             }
                    
        //             }
        //             })
        //             .catch(error => {
        //             console.log('error',error);
        //             })
        // }
    }
};
</script>

<style>

@media only screen and (min-width : 250px) and (max-width : 991px) 
{
        #Desktopview
        {
            display: none;
        }
        #mobileview
        {
            display: block;
        }
}

@media only screen and (min-width : 991px) 
{
        #mobileview
        {
            display:none;
        }
        #Desktopview
        {
            display: block;
        }
}


</style>