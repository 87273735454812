import crypto from "crypto";

export default async function ({ store, req, $axios, query, redirect, params, error }) {
    try {
        var encrypted = query.encrypted;
        var customer_id;
        var data = params.id;

        // Check if `data` contains special characters (validate plain data)
        if (/[^a-zA-Z0-9-_]/.test(data)) {
            throw new Error("Invalid characters in 'data'");
        }
        
        if (encrypted === 'y') {
            // Base64 and length validation for encrypted data
            if (!isValidBase64(data) || !isValidLength(data)) {
                throw new Error("Invalid or malformed encrypted data.");
            }

            var keyBase64 = "YVc1bWIyMWxlbnB2Wm5samIzVndiMj09eiyOLtzOoSh=";
            var ivBase64 = 'aW5mb21lenpvZnljb3Vwb2==';

            // Attempt decryption
            try {
                var decryptedCipherText = decrypt(data, keyBase64, ivBase64);
                console.log("decryptedCipherText", decryptedCipherText);
                customer_id = decryptedCipherText;
            } catch (decryptionError) {
                throw new Error("Decryption failed. Possibly junk data.");
            }

            console.log('decodeURIComponent: ', decryptedCipherText);
        } else {
            customer_id = data;
        }

        function isValidBase64(str) {
            // Checks if `str` is valid Base64
            return /^[A-Za-z0-9+/=]+$/.test(str);
        }

        function isValidLength(str) {
            // Checks if length matches expected size (e.g., multiple of 16 bytes for AES)
            return str.length % 16 === 0;
        }

        function decrypt(messagebase64, keyBase64, ivBase64) {
            const key = Buffer.from(keyBase64, 'base64');
            const iv = Buffer.from(ivBase64, 'base64');
            const decipher = crypto.createDecipheriv(getAlgorithm(key), key, iv);
            let decrypted = decipher.update(messagebase64, 'hex');
            decrypted += decipher.final('utf8');
            return decrypted;
        }
        
        function getAlgorithm(key) {
            console.log("Key value", key, key.length);
            switch (key.length) {
                case 16:
                    return 'aes-128-cbc';
                case 32:
                    return 'aes-256-cbc';
            }
            throw new Error('Invalid key length: ' + key.length);
        }

        console.log('customer_id@@@@@@@@', customer_id);

        if (req && process.server) {
            var customersettingURL = `http://${req.headers.host}/api/mzapps/customersetting`;
            var customerDetailsURL = `http://${req.headers.host}/api/mzapps/customer/${customer_id}`;
            var customer_setting = await $axios.$get(customersettingURL);
            var customer_details = await $axios.$get(customerDetailsURL);
            console.log('customer_setting', customer_setting);
            console.log('customer_details', customer_details);
        }

        if (encrypted === 'y') {
            console.log("hello#1");
            redirect(`/${params.id}/shop/sidebar/list?lang=en&encrypted=y`);
        } else {
            console.log("hello#2");
            redirect(`/${params.id}/shop/sidebar/list?lang=en`);    
        }
    } catch (err) {
        console.log('errorrrrrrr###', err);
        error({ statusCode: 404, message: 'Post not found' });
    }
}