<template>
    <div class="product-gallery product-gallery-vertical" v-if="product">
        <div class="row m-0">
            <figure class="product-main-image">
                <!-- <span class="product-label label-new" v-if="product.new">New</span>
                <span class="product-label label-sale" v-if="product.sale_price">Sale</span>
                <span class="product-label label-top" v-if="product.top">Top</span> -->
                <span class="product-label label-out" v-if="product.available_coupon === 0">{{$t('components.galleryvertical.out_of_stock')}}</span>
                <img
                    id="product-zoom"
                    v-lazy="product.product_images.length > 0 ? product.product_images[currentIndex].product_image : merchantImage"
                    alt="product"
                />

                <a
                    href="#"
                    id="btn-product-gallery"
                    class="btn-product-gallery"
                    @click.prevent="openLightBox"
                >
                    <i class="icon-arrows"></i>
                </a>
            </figure>

            <div id="product-zoom-gallery" class="product-image-gallery">
                <a
                    class="product-gallery-item h-100 h-lg-auto carousel-dot"
                    :class="{active: currentIndex== index}"
                    href="#"
                    v-for="(smPicture, index) in product.product_images"
                    :key="index"
                    @click.prevent="changePicture(index)"
                >
                    <img
                        :src="`${smPicture.product_image}`"
                        alt="product side"
                    />
                </a>
            </div>

            <light-box
                ref="lightbox"
                class="light-box"
                :media="lightBoxMedia"
                :show-caption="true"
                :show-light-box="false"
            ></light-box>
        </div>
    </div>
</template>
<script>
import LightBox from 'vue-image-lightbox';

import { VueLazyloadImage } from 'vue-lazyload';
import 'vue-image-lightbox/dist/vue-image-lightbox.min.css';
import { baseUrl } from '~/repositories/repository.js';

export default {
    components: {
        LightBox
    },
    props: {
        product: {
            type: Object,
            default: function() {
                return {
                    sm_pictures: [],
                    pictures: []
                };
            }
        }
    },
    data: function() {
        return {
            baseUrl: baseUrl,
            currentIndex: 0,
            merchantDetails: '',
            merchantImage: '',
            environment: ''
        };
    },
    computed: {
        lightBoxMedia: function() {
            console.log('this.product.product_images',this.product.product_images);
            if (this.product.product_images !== null && this.product.product_images .length > 0) {
                console.log('ifff');
                return this.product.product_images.reduce((acc, cur) => {
                    console.log('acc-if',acc)
                    return [
                    ...acc,
                    {
                        thumb: `${cur.product_image}`,
                        src: `${cur.product_image}`,
                        caption: this.product.product_name
                    }
                ];  
            }, []);
            }else{
                console.log('acc-else')
                return [
                    {
                        thumb: `${this.merchantImage}`,
                        src: `${this.merchantImage}`,
                        caption: this.product.product_name
                    }
                ];
            }
            
        }
    },
    mounted() {
        this.environment = this.$store.state.sessionStorage.environment
        this.getMerchantDetails();
    },
    methods: {
        faroLog(data) {
            var message = "application:" + "Mz-WALLET, environment:" + `${this.environment}` + ", level:" + `${data.level}` + ", module:" + `CartMenu Page (${data.api})` + ", msg:" + `${data.msg}` + ", time:" + `${new Date()}`
            return message
        },
        changePicture: function (index) {
            this.currentIndex = index;
        },
        openLightBox: function() {
            this.$refs.lightbox.showImage(this.currentIndex);
        },
                            // <================================ Merchant Detail Api function ====================================>

            getMerchantDetails: function() {
            var url = `/api/mzapps/merchant/${this.$store.state.sessionStorage.merchant_id}?country_code=${this.country}&timestamp=${new Date().getTime()}`
            this.$axios.$get(url).then(response => {
                this.merchantDetails = response.merchant;
                console.log('gallerymerchantDetails',this.merchantDetails);
                // document.title = `Official E-Voucher Store | ${this.merchantDetails.merchant_name}`
                this.merchantImage = this.merchantDetails.merchant_logourl
            }).catch(e => {
                var error_params = {
                    msg: e.data ? e.data.message : e,
                    api: 'merchant Api',
                    level: 'error'
                }
                var error_text = this.faroLog(error_params)
                faro.api.pushError(new Error(error_text))
            })
        },
    }
};
</script>