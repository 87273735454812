<template>
    <header class="header header-7">
        <sticky-header>
            <div class="header-middle sticky-header">
                <div class="container">
                    <div class="header-left">
                        <button class="mobile-menu-toggler" @click="openMobileMenu">
                            <span class="sr-only">{{$t("components.ErrorHeaderDefault.toggle_mobile_menu")}}</span>
                            <i class="icon-bars"></i>
                        </button>

                        <div class="logo">
                            <img
                                v-lazy="merchantDetails.merchant_logourl"
                                class="bg-white"
                                alt="Merchant Logo"
                                width="100"
                                height="22"
                            />
                        </div>
                    </div>

                    <div class="header-right">
                        <main-menu></main-menu>
                        <!-- <header-search></header-search> -->
                        <!-- <wishlist-menu></wishlist-menu> -->
                        <!-- <cart-menu></cart-menu> -->
                    </div>
                </div>
            </div>
        </sticky-header>
    </header>
</template>

<script>
// import CartMenu from '~/components/partial/headers/shared/CartMenu';
import MainMenu from '~/components/partial/headers/shared/MainMenu';
import StickyHeader from '~/components/elements/StickyHeader';

export default {
    components: {
        // CartMenu,
        MainMenu,
        StickyHeader
    },
    data() {
        return {
            merchantDetails: '',
            environment: ''
        }
    },
    computed: {
        isFullwidth: function() {
            return this.$route.path.includes('fullwidth');
        }
    },
    methods: {
        faroLog(data) {
            var message = "application:" + "Mz-WALLET, environment:" + `${this.environment}` + ", level:" + `${data.level}` + ", module:" + `CartMenu Page (${data.api})` + ", msg:" + `${data.msg}` + ", time:" + `${new Date()}`
            return message
        },
        openMobileMenu: function () {
            document.querySelector('body').classList.add('mmenu-active');
        },

        //--------------- Merchant Details Api ----------

        getMerchantDetails: function() {
            var url = `/api/mzapps/merchant?timestamp=${new Date().getTime()}`
            this.$axios.$get(url).then(response => {
                this.merchantDetails = response.merchant;
                // document.title = `Catalogue | ${this.merchantDetails.merchant_name}`
                console.log('merchantDetails',this.merchantDetails.merchant_logourl);
                this.$store.commit('sessionStorage/assignMerchantItem', this.merchantDetails);
                console.log('merchantDetails###', this.$store.state.sessionStorage.merchantDetails.merchant_imageurl);
            }).catch(e => {
                var error_params = {
                    msg: e.data ? e.data.message : e,
                    api: 'merchant Api',
                    level: 'error'
                }
                var error_text = this.faroLog(error_params)
                faro.api.pushError(new Error(error_text))
            })
        },
    },
    mounted: function () {
        this.environment = this.$store.state.sessionStorage.environment
        this.getMerchantDetails()
    },
};
</script>