let faroInstance = null;

const initializeFaro = async (app, inject) => {
  if (process.client) {
    const { getWebInstrumentations, initializeFaro } = await import('@grafana/faro-web-sdk');

    faroInstance = initializeFaro({
      url: 'https://faro-collector-prod-ap-southeast-1.grafana.net/collect/09fc13071a435e54691d1c0bd1018203',
      app: {
        name: 'Mezzofy Wallet',
        version: '1.0.0',
        environment: 'production'
      },
      instrumentations: [
        ...getWebInstrumentations(),
      ],
    });

    inject('faro', faroInstance);
  }
};

export default async ({ app }, inject) => {
  await initializeFaro(app, inject);
};