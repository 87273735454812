<template lang="html">
    <div style="height:100%">
        <div class="page-wrapper" style="height:100%">
            <!-- <header-default></header-default> -->
            <LoginHeaderDefault/>
            <nuxt></nuxt>
            <footer-default></footer-default>
            <button id="scroll-top" ref="scrollTop" title="Back to Top" @click.prevent="scrollTop">
                <i class="icon-arrow-up"></i>
            </button>
        </div>
        <div class="mobile-menu-overlay" @click="hideMobileMenu"></div>
        <mobile-menu-one></mobile-menu-one>
    </div>
</template>

<script>
import LoginHeaderDefault from '~/components/partial/headers/LoginHeaderDefault';
import FooterDefault from '~/components/partial/footers/FooterDefault';
import { isSafariBrowser, isEdgeBrowser } from '~/utilities/common';

export default {
    components: {
        LoginHeaderDefault,
        FooterDefault: () =>
            import('~/components/partial/footers/FooterDefault'),
        MobileMenuOne: () => import('~/components/partial/home/MobileMenuOne')
    },
    mounted: function() {
        let scrollTop = this.$refs.scrollTop;
        document.addEventListener(
            'scroll',
            function() {
                if (window.pageYOffset >= 400) {
                    scrollTop.classList.add('show');
                } else {
                    scrollTop.classList.remove('show');
                }
            },
            false
        );
    },
    methods: {
        scrollTop: function() {
            if (isSafariBrowser() || isEdgeBrowser()) {
                let pos = window.pageYOffset;
                let timerId = setInterval(() => {
                    if (pos <= 0) clearInterval(timerId);
                    window.scrollBy(0, -120);
                    pos -= 120;
                }, 1);
            } else {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            }
        },
        hideMobileMenu: function() {
            document.querySelector('body').classList.remove('mmenu-active');
        }
    }
};
</script>
<style >
/* #__layout{
    height: 100% !important;
}
#__nuxt{
    height: 100% !important;
} */
</style>