import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _8ba848f6 = () => interopDefault(import('../pages/balance.vue' /* webpackChunkName: "pages/balance" */))
const _ad2ebb28 = () => interopDefault(import('../pages/callback.vue' /* webpackChunkName: "pages/callback" */))
const _0d78fe0a = () => interopDefault(import('../pages/forgot.vue' /* webpackChunkName: "pages/forgot" */))
const _5fe5e536 = () => interopDefault(import('../pages/heartbeat.vue' /* webpackChunkName: "pages/heartbeat" */))
const _6b97c436 = () => interopDefault(import('../pages/loading.vue' /* webpackChunkName: "pages/loading" */))
const _1f97afad = () => interopDefault(import('../pages/overstatus.vue' /* webpackChunkName: "pages/overstatus" */))
const _5a12bc74 = () => interopDefault(import('../pages/overwrite.vue' /* webpackChunkName: "pages/overwrite" */))
const _18b9815a = () => interopDefault(import('../pages/pinredeem.vue' /* webpackChunkName: "pages/pinredeem" */))
const _bbaa4c88 = () => interopDefault(import('../pages/recovery.vue' /* webpackChunkName: "pages/recovery" */))
const _f3310a96 = () => interopDefault(import('../pages/redeemstatus.vue' /* webpackChunkName: "pages/redeemstatus" */))
const _8c9e7042 = () => interopDefault(import('../pages/refund.vue' /* webpackChunkName: "pages/refund" */))
const _d20e845a = () => interopDefault(import('../pages/resetpassword.vue' /* webpackChunkName: "pages/resetpassword" */))
const _608e6f62 = () => interopDefault(import('../pages/retrivestatus.vue' /* webpackChunkName: "pages/retrivestatus" */))
const _a9f611dc = () => interopDefault(import('../pages/transfer.vue' /* webpackChunkName: "pages/transfer" */))
const _a6ca8af8 = () => interopDefault(import('../pages/transferstatus.vue' /* webpackChunkName: "pages/transferstatus" */))
const _b08e4ce4 = () => interopDefault(import('../pages/updatecustomer.vue' /* webpackChunkName: "pages/updatecustomer" */))
const _7dab1056 = () => interopDefault(import('../pages/pages/404.vue' /* webpackChunkName: "pages/pages/404" */))
const _2ccd0a8a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _a51e7fb4 = () => interopDefault(import('../pages/_id/index.vue' /* webpackChunkName: "pages/_id/index" */))
const _70bc17ae = () => interopDefault(import('../pages/_id/shop/sidebar/_type.vue' /* webpackChunkName: "pages/_id/shop/sidebar/_type" */))
const _a56dc9ec = () => interopDefault(import('../pages/_id/_resetpassword/index.vue' /* webpackChunkName: "pages/_id/_resetpassword/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/balance",
    component: _8ba848f6,
    name: "balance"
  }, {
    path: "/callback",
    component: _ad2ebb28,
    name: "callback"
  }, {
    path: "/forgot",
    component: _0d78fe0a,
    name: "forgot"
  }, {
    path: "/heartbeat",
    component: _5fe5e536,
    name: "heartbeat"
  }, {
    path: "/loading",
    component: _6b97c436,
    name: "loading"
  }, {
    path: "/overstatus",
    component: _1f97afad,
    name: "overstatus"
  }, {
    path: "/overwrite",
    component: _5a12bc74,
    name: "overwrite"
  }, {
    path: "/pinredeem",
    component: _18b9815a,
    name: "pinredeem"
  }, {
    path: "/recovery",
    component: _bbaa4c88,
    name: "recovery"
  }, {
    path: "/redeemstatus",
    component: _f3310a96,
    name: "redeemstatus"
  }, {
    path: "/refund",
    component: _8c9e7042,
    name: "refund"
  }, {
    path: "/resetpassword",
    component: _d20e845a,
    name: "resetpassword"
  }, {
    path: "/retrivestatus",
    component: _608e6f62,
    name: "retrivestatus"
  }, {
    path: "/transfer",
    component: _a9f611dc,
    name: "transfer"
  }, {
    path: "/transferstatus",
    component: _a6ca8af8,
    name: "transferstatus"
  }, {
    path: "/updatecustomer",
    component: _b08e4ce4,
    name: "updatecustomer"
  }, {
    path: "/pages/404",
    component: _7dab1056,
    name: "pages-404"
  }, {
    path: "/",
    component: _2ccd0a8a,
    name: "index"
  }, {
    path: "/:id",
    component: _a51e7fb4,
    name: "id"
  }, {
    path: "/:id/shop/sidebar/:type?",
    component: _70bc17ae,
    name: "id-shop-sidebar-type"
  }, {
    path: "/:id/:resetpassword",
    component: _a56dc9ec,
    name: "id-resetpassword"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
