<template>
    <div v-sticky="!isSidebar" sticky-offset="{ top: 69 }" v-if="(customerSettingDetails.secure_status === 'A' && customers_details.profile_status === 'A' && $store.state.sessionStorage.login_successfull) || customerSettingDetails.secure_status === 'I'" >
                        <button
                                class="sidebar-fixed mr-2"
                                @click="toggleSidebar"
                                v-if="isSidebar"
                            >
                                <i class="la la-filter" style="color:#000; font-size:31px; margin-left: -10px;"></i>
                            </button>

                        <div class="sidebar-filter-overlay" @click="hideSidebar"></div>

                        <!-- <shop-sidebar-one :is-sidebar="isSidebar" :productTags="couponTagList" :id="customer_id"></shop-sidebar-one> -->
                    </div>
</template>
<script>
// import { mounted } from 'vueisotope';
import { mapGetters, mapActions } from 'vuex';
import { baseUrl } from '~/repositories/repository';

export default {
    data: function() {
        return {
            baseUrl: baseUrl,
            catalogueId:'',
            paymentDetails:'',
            merchantDetails:'',
            merchantImage:'',
            catalogueDetails:'',
            isSidebar: true,
            environment: '',
            customers_details: '',
            customerSettingDetails: '',
            customer_id: '',

        };
    },
    mounted: function () {
        this.environment = this.$store.state.sessionStorage.environment
        console.log('this.$store.state.sessionStorage.catalogueDetails', this.$store.state.sessionStorage.catalogueDetails);

        this.merchantDetails = this.$store.state.sessionStorage.merchantDetails;
        this.merchantImage = this.merchantDetails.merchant_logourl;
         this.customer_id = this.$route.params.id
        // this.getActivePayment();
        if (this.customer_id !== undefined && this.customer_id !== null) {
            this.GetCustomer();
        }
        this.getCustomerSetting()
        // this.getActivePayment()
    },
    computed: {
        ...mapGetters('cart', ['cartList', 'priceTotal', 'qtyTotal'])
    },
    methods: {

                             // <----------- Get Coustomer Details Api Function --------------->
        async GetCustomer() {

            console.log('customer_id', this.customer_id);
            var encrypt = this.$route.query.encrypted ? this.$route.query.encrypted : 'N'
            await this.$axios.$get(`/api/mzapps/customer/${this.customer_id}?encrypted=${encrypt}`).then(response => {
                console.log('customer respponse', response);
                this.customers_details = response;
                // var customerGroupId = this.customers_details.customer_group_id
                console.log('customerGroupId', this.customers_details);
            }).catch(e => {
                console.log("customer Error ", e);
                var error_params = {
                    msg: e.data ? e.data.message : e,
                    api: 'customer Api',
                    level: 'error'
                }
                var error_text = this.faroLog(error_params)
                faro.api.pushError(new Error(error_text))
            })

        },

        getCustomerSetting: async function () {
            var url = `/api/mzapps/customersetting`;
            this.$axios.$get(url).then(response => {
                this.customerSettingDetails = response.customer_setting;
                console.log("customerSettingDetails", this.customerSettingDetails);            
            }).catch(e => {
                console.log("customer Error ", e);
                var error_params = {
                    msg: e.data ? e.data.message : e,
                    api: 'customersetting Api',
                    level: 'error'
                }
                var error_text = this.faroLog(error_params)
                faro.api.pushError(new Error(error_text))
            })
        },

        faroLog(data) {
            var message = "application:" + "Mz-WALLET, environment:" + `${this.environment}` + ", level:" + `${data.level}` + ", module:" + `CartMenu Page (${data.api})` + ", msg:" + `${data.msg}` + ", time:" + `${new Date()}`
            return message
        },
        toggleSidebar: function () {
            if (
                document
                .querySelector('body')
                .classList.contains('sidebar-filter-active')
            ) {
                document
                    .querySelector('body')
                    .classList.remove('sidebar-filter-active');
            } else {
                document
                    .querySelector('body')
                    .classList.add('sidebar-filter-active');
            }
        },

        hideSidebar: function () {
            document
                .querySelector('body')
                .classList.remove('sidebar-filter-active');
        },
        resizeHandler: function () {
            if (window.innerWidth > 991) this.isSidebar = false;
            else this.isSidebar = true;
        },
        
        ...mapActions('cart', ['removeFromCart']),
                            // <----------- Active Payment Api --------------->

        getActivePayment: function () {
            var url = `/api/mzapps/activepayment?timestamp=${new Date().getTime()}`
            this.$axios.$get(url).then(response => {
                response.results[0].payment.currency = (response.results[0].payment.currency === 'JPY') ? '¥' : response.results[0].payment.currency;
                this.paymentDetails = response.results[0].payment;
                // console.log('this.paymentDetails',this.paymentDetails);
            }).catch(e => {
                var error_params = {
                    msg: e.data ? e.data.message : e,
                    api: 'activepayment Api',
                    level: 'error'
                }
                var error_text = this.faroLog(error_params)
                faro.api.pushError(new Error(error_text))
                // alert('hek')
            })
        },

    },
};
</script>
<style>

@media only screen and (min-width: 992px)  {
    .sidebar-fixed{
        display: none !important;
    }
}
@media only screen and (max-width : 991px) {
        .isSidebar {
            display:block !important;
    }
    .la .la-filter{
        display:block !important;
    }
    .sidebar-fixed{
    position:sticky;
    display:block !important;
    left: 0;
	top: 49vh;
	width: 30px;
	height: 30px;
	z-index: 1001;
	color: #fff;
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
	/* background-color: rgba( 0,0,0, .2); */
}
}




</style>